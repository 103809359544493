<template>
    <v-container>
        <memories/>
    </v-container>
</template>

<script>
import memories from '../home_sections/memories.vue'

export default {
  components: {
    memories
  }
}
</script>
