<template>
  <div class="main">
    <v-snackbar class="white--text text-center" top v-model="snackbar" :timeout="3000" :color="notificationType">{{notificationText}}</v-snackbar>
    <v-container>
      <v-row justify="center" v-if="loading" class="my-15">
        <v-progress-circular
          :size="70"
          :width="7"
          class="mt-15"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-row>
      <v-row v-masonry transition-duration="0.3s" item-selector=".mason-item" v-if="!loading && trips.length" justify="center">
        <v-col v-masonry-tile class="mason-item" cols="12" sm="6" md="6" v-for="item in trips" :key="item.id">
          <trip-card :transportations="transportations" @delete="deletePlan" @update="updatePlan" :trip="item" />
        </v-col>
      </v-row>

      <div class="empty" v-if="!loading && !trips.length">
        <v-img :src="require('@/assets/images/people.svg')"></v-img>
        <v-btn x-large color="primary" elevation="0" class="rounded-lg px-5 d-block mx-auto my-5" @click="$router.push({ name: 'planAdd' })">
          <v-icon>mdi-plus</v-icon>
          add new trip
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import tripCard from '@/components/plan_trip/tripCard.vue'
import planner from '@/web_services/planner.js'

export default {
  components: { tripCard },
  data () {
    return {
      trips: [],
      snackbar: false,
      notificationType: '',
      notificationText: '',
      loading: true,
      transportations: []
    }
  },
  methods: {
    updatePlan (payload, id) {
      planner.updatePlanBasicInfo(payload, id).then(response => {
        this.showNotification('success', response.data.message)
        this.trips.map(item => {
          if (item.id === id) {
            item.name = payload.name
            item.description = payload.description
          }
        })
      }).catch(err => {
        this.showNotification('error', err.response.data.message)
      })
    },
    deletePlan (id) {
      planner.deletePlan(id).then(response => {
        this.showNotification('success', response.data.message)
        this.trips.splice(this.trips.findIndex(a => a.id === id), 1)
        // this.trips = response.data.data
      }).catch(err => {
        this.showNotification('error', err.response.data.message)
      })
    },
    showNotification (color, text) {
      this.snackbar = true
      this.notificationType = color
      this.notificationText = text
    }
  },
  created () {
    planner.listPlans().then(res => {
      this.trips = res.data.data
    }).catch(() => {}).finally(() => {
      this.loading = false
    })
    planner.listTransportations().then(response => {
      this.transportations = response.data.data
    }).catch(() => {})
  }
}
</script>

<style>
/* .main .empty {
  transform: translateY(12vh);
} */
@media screen and (max-width: 600px) {
  .plans-list {
    transform: translateY(-50px);
  }
}
</style>
